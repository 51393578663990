ful-select {
    --ful-select-box-shadow-invalid: 0 0 0 .25rem rgba(var(--bs-danger-rgb, 220, 53, 69), .25);
    --ful-select-border-color-invalid: var(--bs-form-invalid-border-color);

    &:not(:defined) {
        visibility: hidden;
    }

    display: block;

    & .ts-control {
        /*fixes border radius on box-shadow*/
        border-radius: inherit !important;
    }

    &:has(.is-invalid) .focus .ts-control {
        box-shadow: var(--ful-select-box-shadow-invalid);
    }

    &:has(.is-invalid) .form-select,
    &:has(.is-invalid) .form-control {
        border-color: var(--ful-select-border-color-invalid) !important;
    }
}